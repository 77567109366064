import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Error from '../components/Error';


const GET_PRODUCT_BY_ID = gql`
  query GetProduct($id: ID!) {
    brochure2Product(id: $id) {
      data {
        id
        attributes {
          productID
          title
          price
          description
          product_2_category {
            data {
              id
              attributes {
                title
              }
            }
          }
          product_images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Product() {
  const [currentUrl, setCurrentUrl] = useState('');

  const location = useLocation();

  const path = location.pathname.split('/');
  const id = path[3];

  console.log(id, 'id')
  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id: id },
  });

  console.log(data, "page data");

  useEffect(() => {
    setCurrentUrl(window.location.href);

    const handleUrlChange = () => {
      setCurrentUrl(window.location.href);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  if (loading) {
    return <Loading />
  }
  if(error || id === 'undefined') {
    return <Error />
  }

  const { productID, title, description, product_images, product_2_category, price } = data.brochure2Product.data.attributes;
  const imageList = product_images && product_images.data ? product_images.data : [];
  const categoryName = product_2_category.data.attributes.title;

  return (
    <div className='w-full h-full overflow-y-auto'>
      <div className='flex w-full'>
        {/* navigation path */}
        <nav className='text-[8px] md:w-[80%] w-1/2 md:p-8 p-2'>
          <ul className='uppercase inline-flex space-x-1'>
            <li className='underline'><Link to='/main'>Our Products</Link></li>
            <li>/</li>
          <li className='underline'><Link to={`/category/${categoryName}`} state={{cat: product_2_category.data.id}} >{categoryName}</Link></li>
          </ul>
        </nav>


      
      </div>
      <div className='md:p-20 p-2 md:pt-0 flex flex-col '>
        <div className='space-y-4'>
          <div>
          <h1 className='md:text-4xl text-3xl font-extralight uppercase'>{title}</h1>
          <div className='flex justify-between'>
          <p className='max-md:font-light text-[10px]'> {productID}</p>
              <Link to='/priceList'className='font-bold text-[8px] inline-flex my-auto' state={{productID}}>
                View Trade Price &rarr;
              </Link>
          </div>
          </div>
        
        <div className='space-y-2 w-full flex flex-col overflow-hidden'>
          {imageList.map((product_image, index) => (
            <div className='w-full h-full max-h-96 overflow-hidden flex'>
              <div className='m-auto hover:scale-[200%] transform transition duration-500'>
                <LazyLoadImage
                  key={product_image.id}
                  src={product_image.attributes.url}
                  alt={` ${product_image.id}`}
                  className='w-full object-contain max-h-80 '
                  effect='blur'
              />
          </div>
        </div>
        ))}
          </div>
          <div className='md:inline-flex md:space-x-8 py-4'>
            
            <div className='space-y-4'>
            <div className='text-xs w-full'>
  {description.split('\n').map((paragraph, index) => (
    <p key={index} className='pb-1'>{paragraph}</p>
  ))}
</div>
              {/* <span className='text-black block text-xs font-light md:text-[8px]'>£{price}</span> */}
              <Link to='/priceList'className='font-bold text-[8px] inline-flex' state={{productID}}>
                View Trade Price &rarr;
              </Link>
            </div>
            
          </div>
          

        </div>
        <Outlet />

                {/* qr code */}
        <div className='bg-gradient-to-r from-black via-black to-gray-700 w-full h-full flex px-4 py-4 float-right text-white text-sm justify-between'>
          <div className='w-2/3 justify-between h-[60px] md:h-[100px] flex flex-col'>
            <div>
              <Link to='enquiry' state={productID} className=''>
              <p className='font-semibold'>
                Want to learn more about this product?
              </p>
              
              </Link>
              <p className='font-light text-xs max-md:hidden'>Scan the QR Code</p>
              
            </div>
            <div className='text-xs'>
              <Link to='https://www.aqualona.com/'>Contact us at aqualona.com</Link>
            </div>
          </div>
          <div className='bg-white h-auto w-auto p-2 flex max-md:hidden h-[60px] w-[100px]'>
            <QRCode className='w-full m-auto h-20' value={`${currentUrl}/enquiry`} />

          </div>
          <div className=' h-auto w-auto p-2 flex md:hidden  flex'>
          <Link to='enquiry' state={productID} className='m-auto flex'>
            <span className='m-auto h-full w-full flex'>&rarr;</span>
          </Link>

          </div>
      </div>
      </div>
    </div>

  );
}
