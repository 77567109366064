import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

const GET_CATEGORY = gql`
  query GetCategory($title: String!) {
    product2Categories(filters: { title: { contains: $title } }) {
      data {
        id
        attributes {
          title
          coverImageURL
        }
      }
    }
  }
`;

const GET_PRODUCTS_FOR_CATEGORY = gql`
  query GetCats($id: ID!) {
    product2Category(id: $id) {
      data {
        attributes {
          brochure_products(pagination: { limit: 200 }, sort: "orderBy") {
            data {
              id
              attributes {
                productID
                title
                description
                product_images {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                product_2_category {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Register fonts
Font.register({
  family: 'Plus Jakarta Sans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KU7NShXUEKi4Rw.ttf', fontWeight: '200' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_907NShXUEKi4Rw.ttf', fontWeight: '300' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NShXUEKi4Rw.ttf', fontWeight: 'normal' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NShXUEKi4Rw.ttf', fontWeight: '500' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_d0nNShXUEKi4Rw.ttf', fontWeight: '600' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_TknNShXUEKi4Rw.ttf', fontWeight: '700' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KUnNShXUEKi4Rw.ttf', fontWeight: '800' },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    paddingVertical: 20,
    fontFamily: 'Plus Jakarta Sans',
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    textTransform: 'uppercase',
    marginVertical: 10,
    paddingHorizontal: 20,
  },
  image: {
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    paddingVertical: 10,
    paddingHorizontal: 12,
    width: '100%',
    height: 150,
    pageBreakInside: 'avoid',
  },
  listText: {
    flexGrow: 1,
    width: '60%',
  },
  listId: {
    fontSize: 16,
    width: '20%',
    textTransform: 'uppercase',
  },
  listTitle: {
    fontSize: 16,
    width: '100%',
    textTransform: 'uppercase',
    paddingBottom: 12,
    fontWeight: 'normal',
  },
  listDescription: {
    fontSize: 12,
    overflow: 'hidden',
    paddingRight: 60,
    fontWeight: 'light',
  },
  imageContainer: {
    width: '20%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: 2,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#E4E4E4',
  },
  imageProduct: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const CategoryPDF = () => {
  const { title } = useParams();
  const [categoryId, setCategoryId] = useState(null);
  const [loadingPDF, setLoadingPDF] = useState(true);

  const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(GET_CATEGORY, {
    variables: { title },
    onCompleted: (data) => {
      const category = data.product2Categories.data[0];
      if (category) {
        setCategoryId(category.id);
      }
    },
  });

  const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(GET_PRODUCTS_FOR_CATEGORY, {
    variables: { id: categoryId },
    skip: !categoryId, // Skip the query if categoryId is not set
  });

  useEffect(() => {
    if (!loadingCategories && !loadingProducts && categoryId) {
      setLoadingPDF(false);
    }
  }, [loadingCategories, loadingProducts, categoryId]);

  if (loadingCategories || loadingProducts || loadingPDF) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <div className='w-full text-center'>Loading data...</div> {/* Replace with spinner if you have one */}
      </div>
    );
  }

  if (errorCategories || errorProducts) {
    return <div>Error: {errorCategories?.message || errorProducts?.message}</div>;
  }

  const productCategoryData = dataCategories.product2Categories.data[0];
  const products = dataProducts.product2Category?.data?.attributes?.brochure_products?.data || [];

  return (
    <div className='w-full h-full flex justify-center items-center'>
      <p className='absolute top-0 -z-10 w-full h-full flex justify-center items-center'>Generating PDF...</p>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document title={`Aqualona | ${title}`}>
          <Page size="A4" style={styles.page} wrap>
            <View>
              <Text style={styles.title}>{title}</Text>
              {productCategoryData?.attributes?.coverImageURL && (
                <Image
                  src={productCategoryData.attributes.coverImageURL}
                  style={styles.image}
                />
              )}
            </View>
            <View>
              {products.map((product) => (
                <View style={styles.listItem} wrap={false} key={product.id}>
                  <Text style={styles.listId}>{product.attributes.productID}</Text>
                  <View style={styles.listText}>
                    <Text style={styles.listTitle}>{product.attributes.title}</Text>
                    <Text style={styles.listDescription}>
                      {product.attributes.description.split('\n')[0].slice(0, 160)}...
                    </Text>
                  </View>
                  {product.attributes.product_images?.data?.length > 0 && (
                    <View style={styles.imageContainer}>
                      <Image
                        src={product.attributes.product_images.data[0].attributes.url}
                        style={styles.imageProduct}
                      />
                    </View>
                  )}
                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default CategoryPDF;
