import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Loading from '../components/Loading';

const GET_CATEGORIES = gql`
  query GetCategories($categoryTitle: String!) {
    product2Categories(filters: { title: { eq: $categoryTitle } }) {
      data {
        id
        attributes {
          title
          coverImageURL
          brochure_products {
            data {
              id 
              attributes {
                productID
                title
                price
                description
                orderBy
                product_images {
                  data {
                    id 
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


const GET_PRODUCTS_FOR_CATEGORY = gql`
query GetCats($id: ID!) {
  product2Category(id: $id ) {
    data {
      attributes {
        brochure_products {
          data {
            id
            attributes {
              productID
              title
              description
              product_images {
                data {
                  attributes{
                    url
                  }
                }
              }
              product_2_category {
                data {
                  id
                  attributes {
                   title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default function Collection() {

  const location = useLocation();
  const categoryId = location.state ? location.state.cat : null;

  const { id: collectionTitle } = useParams();


  const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: { categoryTitle: collectionTitle },
  });
  // const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(GET_PRODUCTS_FOR_CATEGORY, {
  //   variables: { id: categoryId },
  // });


  if (loadingCategories) {
    return <Loading />;
  }

  if (errorCategories) {
    return <div>Error fetching categories: {errorCategories.message}</div>;
  }
  if (categoryId === null) {
    return <div>Category not found.</div>;
  }

//   if (loadingProducts) {
//     return <Loading />;
//   }

//   if (errorProducts) {
//     return <div>Error fetching products: {errorProducts.message}</div>;
//   }

//   const productCategoryData = dataProducts.product2Category?.data;
// const products = productCategoryData?.attributes?.products?.data || [];

console.log(dataCategories, 'category data');
  const matchedCategory = dataCategories.product2Categories.data.find(
    (category) => 
      category.attributes.title.toLowerCase().replace(/\s+/g, '') === 
      collectionTitle.toLowerCase().replace(/\s+/g, '')
  );
  
const { title, coverImageURL, brochure_products } = dataCategories.product2Categories.data[0].attributes;
  return (
    <div className='overflow-y-auto'>
      <div>
        <Link to='/main' className='underline font-bold text-[9px] p-8'>
          Our Products
        </Link>
      </div>
      <div className='w-full'>
        <h1 className='italic uppercase md:text-6xl text-2xl font-light px-8 py-4'>{collectionTitle}</h1>
        {matchedCategory && (
          <img className="w-full h-[50vh] object-cover" src={matchedCategory.attributes.coverImageURL} alt="Category Main" />
        )}
      </div>
      <div>
      <ul className='w-full flex-col'>
          {brochure_products.data.map((product) => (
        <Link
          to={`/category/${collectionTitle}/${product.attributes.productID}`}
          key={product.id}
          
        >
          <li className='w-full h-[200px] grid grid-cols-2 md:grid-cols-3 grid-rows-2 md:grid-rows-3 md:gap-2 py-4 px-2 md:pl-8 md:pr-4 border-b-[0.8px] border-black transition-colors duration-500 hover:bg-[#857A5C] hover:bg-opacity-25' key={product.id}>
                <span className='md:row-span-5 max-md:text-xs'>{product.attributes.productID}</span>
                <h2 className='uppercase md:col-span-2 max-md:col-start-1 max-md:row-start-2'>{product.attributes.title}</h2>
                <p className='text-[8px] md:col-start-2 md:col-span-2 row-start-2 md:pr-4 row-span-3 max-md:col-start-1 max-md:row-start-3 overflow-y-auto'>{product.attributes.description.slice(0,250)}...</p>
                <div className='row-span-3 col-start-4 row-start-1 max-md:row-span-5 max-md:col-start-2 max-md:row-start-1 w-[150px] max-md:mx-auto my-auto h-[150px]'>
                  {product.attributes.product_images?.data?.length > 0 && (
                    <img
                      src={product.attributes.product_images.data[0].attributes.url}
                      alt=''
                      className='object-cover w-full h-full '
                    />
                  )}
              </div>
          </li>
        </Link>
          ))}
          <li className='text-xs m-auto p-8 italic text-center'>Reach the end</li>
        </ul>
      </div>
    </div>
  );
}
