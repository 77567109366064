import { gql } from "@apollo/client";

const GET_HOME_DATA = gql`
  query {
    homeTwo {
      data {
        attributes {
          pageHeader
          subHeader
          button
          description
          coverImageURL
        }
      }
    }
  }
`;

export { GET_HOME_DATA }