import React from 'react'
import { Link } from 'react-router-dom';
import mid from '../assets/mid.png'
import { gql, useQuery } from '@apollo/client';
import Loading from '../components/Loading';

const GET_ABOUT_US_DATA = gql`
  query {
    aboutUs {
      data {
        attributes {
          pageHeader
          paragraph1
          paragraph2
          slogan
          image1URL
          image2URL
          landscapeImageURL
        }
      }
    }
  }
`;

export default function AboutUs() {

  const {loading, error, data } = useQuery(GET_ABOUT_US_DATA);

  if(loading){
    return <Loading />
  }
  if(error) {
    return <p>Error loading data.</p>
  }

  const {pageHeader, paragraph1, paragraph2, slogan, image1URL, image2URL, landscapeImageURL } = data.aboutUs.data.attributes;
  return (
    <div className='h-[200vh] md:h-full w-full relative'>
      <div className='px-8 pb-16 h-2/3 md:h-1/2'>
        <nav className='py-2'><Link className='underline uppercase text-[10px]' to='/main'>Products</Link></nav>
        <h1 className='text-[46px] font-light'>{pageHeader}</h1>
        <div className='md:inline-flex max-md:flex max-md:flex-col '>
          <div className='text-[10px] md:text-[8px] md:w-1/2 py-8 pr-4 font-light space-y-4 max-md:order-2 max-md:pb-20'>
            <p>{paragraph1}</p>
          </div>
          <div className='md:w-1/2 space-y-4'>
            <img src={image1URL} className='object-contain' />
            <img src={image2URL} />
          </div>
        </div>
      </div>
      <div className='absolute top-1/2 left-0 h-[10%] md:h-[20%] md:inline-flex justify-between pr-8 transform -translate-y-1/2'>
        <img src={mid} className='object-contain'/>
      <p className=' text-[10px] md:text-[8px] md:w-[50%] pl-4 font-light my-auto pt-10 mt-16 text-white leading-loose'>{paragraph2}</p>
      </div>
      <div className='bg-black text-white font-light p-8 pt-20  h-1/3 md:h-1/2 flex flex-col justify-end'>
        <div className='pt-10 space-y-8'>
          <p className='text-[16px]'>{slogan}</p>
          <img src={landscapeImageURL} />
        </div>
      </div>

    </div>
  )
}
