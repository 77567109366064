import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Loading from '../components/Loading';

const GET_CATEGORY_PAGE_DATA = gql`
  query {
    categoryTwo {
      data {
        attributes {
          pageHeader
          paragraph
          coverImageURL
        }
      }
    }
  } 
`;

const GET_CATEGORIES = gql`
query {
  product2Categories(sort: "order") {
    data {
      id
      attributes {
        iconURL
        title
        order
      }
    }
  }
}
`;

const GET_COLLECTIONS = gql`
query {
	collections {
    data {
      id
      attributes {
        pageHeader
        iconURL
        coverImageURL
        paragraph
      }
    }
  }
}
`;

export default function ProductSummary() {
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  const { loading: loadingPage, error: errorPage, data: pageData } = useQuery(GET_CATEGORY_PAGE_DATA);

  const { loading: loadingCollections, error: errorCollections, data: collectionsData } = useQuery(GET_COLLECTIONS);
  console.log(data, "the data")

  if (loading || loadingPage) {
    return <Loading />;
  }

  if (error || errorPage) {
    return <div>Error loading categories: {error.message}</div>;
  }
  const categories = data && data.product2Categories.data;


  const sortedCategories = [...categories].sort((a, b) => a.attributes.order - b.attributes.order);

  console.log(categories, "the categories");



  const {pageHeader, paragraph, coverImageURL } = pageData.categoryTwo.data.attributes;
  return (
    <div className="h-[100%] w-full overflow-y-auto">
      <div className='w-full h-[45%] md:h-[55%] px-12 py-10 relative' style={{backgroundImage: `url(${coverImageURL})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className='h-full top-0 relative space-y-4'>
          <h1 className='md:text-4xl text-3xl font-extralight uppercase'>
            {pageHeader}
          </h1>
          <p className='font-extralight w-1/2 text-[10px]'>{paragraph}</p>
        </div>
      </div>
      <div className="h-[75%] md:h-[65%] border-black flex w-full">

  <ul className="grid grid-cols-2 md:grid-cols-3 grid-rows-5 md:grid-rows-4 h-auto w-full">
    {sortedCategories &&
      sortedCategories
      .map((category, index) => {
        const isLastTwo = categories.length - index <= 2;
        const isLastOne = categories.length - index <= 1;
        const colSpanClass = isLastTwo ? 'md:col-span-3 col-span-2' : '';
        const rowStartClass = isLastOne ? `row-start-${categories.length - index + 3}` : '';

        return (
          <li
            key={category.id}
            className={`border-[0.5px] border-[#C0C0C0] transition duration-1000 flex hover:bg-[#DCD9D3] ${rowStartClass} ${colSpanClass}`}
          >
            <Link
              to={`/category/${category.attributes.title}`}
              className="block m-auto text-center text-[10px] uppercase"
            >
              <img
                src={category.attributes.iconURL}
                className="mx-auto w-[40px] h-[40px] object-contain"
                alt={category.attributes.title}
              />
              <p className='max-md:pt-2'>{category.attributes.title}</p>
            </Link>
          </li>
        );
      })}
    {/* Placeholder element to maintain grid structure */}
    <li className='md:hidden border-[0.5px] border-[#C0C0C0]'></li>
  </ul>
</div>





      
      <div className="inline-flex w-full uppercase font-semiBold h-[10%]">
      <Link to="/about-us" className='border-[1px] border-black border-b-0 border-l-0 w-1/2 p-8 transition duration-1000 flex hover:bg-blue-200'>
        <div className=" text-center  m-auto">
          About Us
        </div>
        </Link>
        <Link to="/priceList" className='border-[1px] border-black border-b-0 border-l-0 w-1/2 p-8 transition duration-1000 flex hover:bg-blue-200'>
        <div className=" text-center m-auto">
          Trade Price
        </div>
        </Link>
        <Link to="/contact" className="border-[1px] border-black border-x-0 border-b-0 w-1/2 p-8 transition duration-1000 flex hover:bg-blue-200">
        <div className=" text-center m-auto" >
          Contact Us
        </div>
        </Link>
      </div>
    </div>
  );
}
