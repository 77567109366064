import React from 'react'

export default function Basket() {
  return (
    <div className='w-60 h-full  bg-brand flex flex-col border border-black border-t-0'>
        <button className='mx-auto'>&darr;</button>
        <div className='block h-full'>
            <ul className='overflow-y-auto h-full max-h-80'>
                <li className='flex h-20 border-2 border-black text-xs'>
                    <div className='w-3/4 m-auto'>
                        <span className=''>ProdID</span>
                        <h2 className='font-semibold'>Title</h2>
                    </div>
                    <div className='w-1/4 h-full border border-red-200'>
                        <img className='object-cover w-full h-full' src='logo192.png' alt='' />
                    </div>
                </li>
                <li className='flex h-20 border-2 border-black text-xs'>
                    <div className='w-3/4 m-auto'>
                        <span className=''>ProdID</span>
                        <h2 className='font-semibold'>Title</h2>
                    </div>
                    <div className='w-1/4 h-full border border-red-200'>
                        <img className='object-cover w-full h-full' src='logo192.png' alt='' />
                    </div>
                </li>
                <li className='flex h-20 border-2 border-black text-xs'>
                    <div className='w-3/4 m-auto'>
                        <span className=''>ProdID</span>
                        <h2 className='font-semibold'>Title</h2>
                    </div>
                    <div className='w-1/4 h-full border border-red-200'>
                        <img className='object-cover w-full h-full' src='logo192.png' alt='' />
                    </div>
                </li>
                <li className='flex h-20 border-2 border-black text-xs'>
                    <div className='w-3/4 m-auto'>
                        <span className=''>ProdID</span>
                        <h2 className='font-semibold'>Title</h2>
                    </div>
                    <div className='w-1/4 h-full border border-red-200'>
                        <img className='object-cover w-full h-full' src='logo192.png' alt='' />
                    </div>
                </li>
                <li className='flex h-20 border-2 border-black text-xs'>
                    <div className='w-3/4 m-auto'>
                        <span className=''>ProdID</span>
                        <h2 className='font-semibold'>Title</h2>
                    </div>
                    <div className='w-1/4 h-full border border-red-200'>
                        <img className='object-cover w-full h-full' src='logo192.png' alt='' />
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}
