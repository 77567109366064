import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
const GET_ALL_PRODUCTS = gql`
  query {
    brochure2Products( pagination: { limit: 400 }) {
      data {
        attributes {
          productID
          title
          price
          product_2_category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;


export default function PriceList() {
  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);
  const location = useLocation();
  const scrollProductID = location.state?.productID;
  console.log(scrollProductID, "scroll to");
  const [highlightedProduct, setHighlightedProduct] = useState(null);
  useEffect(() => {
    if (scrollProductID) {
      const element = document.getElementById(scrollProductID);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setHighlightedProduct(scrollProductID);
      }
    }
  }, [scrollProductID]);
  console.log(data);

    // Sort products by category ID after fetching

const sortedProducts = data && [...data.brochure2Products.data].sort((a, b) => {
  const categoryA = a.attributes.product_2_category.data.id;
  const categoryB = b.attributes.product_2_category.data.id;
  return categoryA.localeCompare(categoryB);
});


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='w-full max-h-full p-2 md:p-8 overflow-auto'>
      <nav>
        <ul className='underline uppercase text-[8px] inline-flex space-x-[1.5px]'>
          <li><Link to='/main'>
            Our Products</Link>
          </li>
          <li className=''> / </li>
          <li className='underline'>
            Trade Price List
          </li>
        </ul>
      </nav>


      <h1 className='font-light text-[50px]'>Trade Price List</h1>

      <ul className=' space-y-0 w-full py-12 '>
        <li>
          <ul className='uppercase grid grid-cols-4 md:grid-cols-8 font-bold border-b border-black text-[12px] overflow-y-scroll'>
            <li className=' max-md:hidden  md:col-span-2'>Product No.</li>
            <li className='col-span-3 md:col-span-5'>Product Name</li>
            <li className='max-md:col-span-1'>Price (£)</li>
          </ul>
        </li>
        {sortedProducts.map((product) => (
          <li key={product.attributes.ProductId} id={product.attributes.productID}
            className={`border-b border-[#C5BEB1] py-3 ${highlightedProduct === product.attributes.productID ? 'bg-gray-300' : ''}`}>
            <ul className='grid md:grid-cols-8 grid-cols-4 justify-between text-[12px] w-full overflow-y-auto'>
              <li className='col-span-2 max-md:col-span-3'>{product.attributes.productID}</li>
              <li className='col-span-3 md:col-span-5 max-md:row-start-2'>{product.attributes.title}</li>
              <li>{product.attributes.price && product.attributes.price !== 0 ? `£${product.attributes.price}` : ''}</li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
