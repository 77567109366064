import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error'
import { AnimatePresence } from 'framer-motion';
import logo from './assets/logo.png'
import HomePage from './pages/HomePage';
import ProductSummary from './pages/ProductSummary';
import Category from './pages/Category';
import PriceList from './pages/PriceList';
import Product from './pages/Product';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import { Link } from 'react-router-dom';
import ContactForm from './components/ContactForm';
import Collection from './pages/Collection';
import Basket from './components/Basket';
import Download from './PDF/Download';
import TradePriceListPDF from './PDF/TradePriceListPDF';
import ProductPDF from './PDF/ProductPDF';
import CategoryPDF from './PDF/CategoryPDF';

const errorLink = onError(({graphQLErrors, networkErrors}) =>{
  if(graphQLErrors) {
    graphQLErrors.map(({message, location, path}) => {
      console.log(`GraphQL Error ${message}`)
    });
  }
});
const link = from([
  errorLink,
  new HttpLink({uri: process.env.REACT_APP_STRAPI_API_URL})

]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});


function App() {
  return (
    <AnimatePresence>

        <ApolloProvider client={client}>
          <div className='w-full min-h-screen bg-gray-200 flex'>
            {/* <div className='absolute top-0 right-0 flex pr-10'>
                <Basket />
            </div> */}
            
            <div className='absolute top-0 left-0 opacity-20 w-40 p-6 hover:opacity-100 transition-opacity'><Link to='/'><img className='invert opacity-20' src={logo} alt='Aqualona' /></Link></div>
              <div className='w-[620px] h-[840px] flex mx-auto drop-shadow-md bg-brand'>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/main" element={<ProductSummary />} />
                  <Route path="/category/:id" element={<Category />} />
                  <Route path='/category/:title/download' element={<CategoryPDF />} />
                  <Route path="/priceList" element={<PriceList />} />
                  <Route path="/priceList/download" element={<TradePriceListPDF />} />
                  <Route path="/category/:categoryName/:id/:productID" element={<Product />} >
                    <Route path="enquiry" element={<ContactForm />} />
                  </Route>
                  <Route path="/category/:categoryName/:id/:productID/download" element={<ProductPDF />}></Route>
                  <Route path='collection/:collectionTitle' element={<Collection />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/about-us" element={<AboutUs />} />          
                </Routes>
              </div>
              <div className='md:fixed bottom-0 right-0 p-2 text-xs max-md:opacity-20 max-md:hidden'>
                <Link to='https://www.plantec.io'><span>Designed by Plantec</span></Link>
              </div>
              <div className='md:fixed top-0 right-0  text-xs max-md:opacity-20 max-md:hidden flex flex-col'>
                <Download />
              </div>
          </div>
        </ApolloProvider>
    </AnimatePresence>
  );
}

export default App;
