import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import Error from '../components/Error';
import ProductList from '../components/ProductList';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { motion } from 'framer-motion';

const GET_CATEGORY = gql`
  query GetCategory($title: String!) {
    product2Categories(filters: {title: { contains: $title } }) {
      data {
        id
        attributes {
          title
          coverImageURL
        }
      }
    }
  }
`;


export default function Category() {

  const navigate = useNavigate();

  const location = useLocation();
  const categoryId = location.state ? location.state.cat : null;

  const path = location.pathname.split('/');

  const categoryTitle = decodeURI(path[2]);

  const [mountProducts, setMountProducts] = useState(false);

  const { loading, error, data } = useQuery(GET_CATEGORY, {
    variables: { title: categoryTitle },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />
  }

  // if (categoryId === null) {
  //   // navigate('/main')
  // }

  console.log(categoryTitle, ": categoryTitle")
  console.log(data, 'new data')

  const productCategoryData = data.product2Categories?.data[0];
// const products = productCategoryData?.attributes?.products?.data || [];
console.log(productCategoryData, 'prod data')

  // const matchedCategory = dataCategories.productCategories.data.find(
  //   (category) => 
  //     category.attributes.title.toLowerCase().replace(/\s+/g, '') === 
  //     categoryName.toLowerCase().replace(/\s+/g, '')
  // );
  
const mountProductList = () => {
  setMountProducts(true);
}
  return (
    <motion.div                   
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, delay: 0.5 }} // Adding a delay of 0.5 seconds before exit animation
    transition={{ duration: 2 }}
     className='overflow-y-auto w-full'>
      <div>
        <Link to='/main' className='underline font-bold text-[9px] p-8'>
          Our Products
        </Link>
      </div>
      <div className='w-full flex flex-col'>
        <h1 className='italic uppercase md:text-6xl text-2xl font-light px-8 py-4'>{productCategoryData.attributes.title}</h1>

          <LazyLoadImage onLoad={mountProductList} effect='blur' className="w-full h-[50vh] object-cover" src={productCategoryData.attributes.coverImageURL} alt="Category Main" />

      </div>
      {mountProducts ? <ProductList mount={mountProducts} categoryId={productCategoryData.id} categoryName={productCategoryData.attributes.title} /> : <Loading />
      }
      
    </motion.div>

  );
}