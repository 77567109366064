import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png'
import { motion } from 'framer-motion';
import { GET_HOME_DATA } from '../GraphQL';
import { useQuery } from '@apollo/client';
import Loading from '../components/Loading';

export default function HomePage() {
    const { loading, error, data } = useQuery(GET_HOME_DATA);

    if(loading) {
       return  <Loading />
    }
    if(error) {
       return <p>Found error while loading data. Please refresh the page.</p>
    }

    const {pageHeader, subHeader, button, description, coverImageURL} = data.homeTwo.data.attributes;


  return (
    <motion.div         initial={{ x: window.innerWidth }}
    animate={{ x: 0 }}
    exit={{ opacity: 0 }} className='grid-flow-row grid-rows-2 w-full h-full p-2 bg-[#C8C6C7]'>
        {/* background image */}
        <div className='w-[100%]! h-[85%] relative' style={{backgroundImage: `url(${coverImageURL})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className='relative h-full '>
                {/* AQUALONA LOGO */}
                <p className='pt-12 text-white text-[14px] mx-auto text-center pb-2'>Powered by</p>
                <img src={Logo} alt='nothing' className='w-[187px] mx-auto' />
                <div className='absolute bottom-10 lg:bottom-24 right-10 w-[55%] text-right text-white'>
                    <h1 className='uppercase  text-[28px]  lg:text-[40px] '>{pageHeader}</h1>
                    <p className='text-[18px] font-light'>{subHeader}</p>
                </div>
            </div>
        </div>
        <div className='h-[15%]'>
            <Link className='flex transition-colors duration-500 hover:bg-[#424242] h-full' to='/main' >
                <div className='font-plus-jakarta-sans mx-auto py-8 space-y-2 text-white '>
                    <h2 className='uppercase inline-flex font-bold tracking-widest mx-auto text-[14px] text-center w-full'> <span className='mx-auto pr-0 mr-0'>{button}</span>
                    <span className='mx-auto'>&rarr;</span>
                    </h2>
                    <p className='text-center text-[10px]'>{description}</p>
                </div>
            </Link>
        </div>
        
    </motion.div>
  )
}
